@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Kalinov Most";
  src: url("./assets/fonts/kalinov_most.otf") format("opentype");
}
@layer {
  * {
    box-sizing: border-box;
  }

  html,
  body {
    font-family: "Montserrat", sans-serif;
    margin: 0;
    color: #ffffff;
    line-height: 150%;
    overflowX: hidden;
  }

  ul,
  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
    transition: 0.3s;
  }

  button {
    transition: 0.3s;
  }

  section {
    @apply flex justify-center min-h-screen overflow-hidden;
  }

  img {
    display: block;
    width: 100%;
  }

 
}

@layer components {
  .manu__text {
    @apply text-white text-xl sm:text-3xl inline-block py-1 hover:text-[#C8A26D] transition-all duration-300;
  }

  input::placeholder,
  textarea::placeholder {
   @apply text-black opacity-70
  }
  
  .main__bg {
    background: linear-gradient(180deg, #2a2d32 0%, #131313 99.17%);
  }

  .header__bg {
    background: linear-gradient(
      180deg,
      rgba(42, 45, 50, 0.85) 0%,
      rgba(19, 19, 19, 0.85) 99.17%
    );
    backdrop-filter: blur(20px);
  }

  .main__linear {
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.11);
    filter: blur(200px);
  }

  .button__main {
    @apply w-80 h-16 sm:h-20 inline-block rounded-full  bg-[#1D1F22] bg-opacity-20 relative transition-all duration-300;
    box-shadow: inset 26px 26px 81px 0 rgba(0, 0, 0, 0.55),
      inset -26px -26px 68px 0 rgba(59, 68, 81, 0.5),
      inset 2px 1px 2px 0 rgba(255, 255, 255, 0.05);
  }

  .button__main:after {
    @apply absolute w-full h-full top-0 left-0 rounded-full opacity-0 transition-all duration-300;
    content: "";
    box-shadow: 18px 18px 36px 0 rgba(0, 0, 0, 0.25),
      -10px -10px 20px 0 rgba(255, 255, 255, 0.19);
  }

  .button__main:hover {
    @apply bg-[#1D1F22];
    box-shadow: none;
  }

  .button__main:hover div {
    @apply text-[#b9bcc5];
  }

  .button__main:hover:after {
    @apply opacity-100;
  }

  /* --------------------- */
  /* service */
  /* --------------------- */

   .linear__bg:before {
    @apply absolute w-[60%] h-[80%] sm:h-[110%] top-0 -left-[40%] sm:-left-[30%] bg-active-0 rounded-full blur-[110px] sm:blur-[230px];
    content:"";
  }

  .prev,
  .next {
    @apply w-[62px] h-[62px]  rounded-full absolute top-1/2 cursor-pointer transition-all duration-300 opacity-80;
    transform: translateY(-50%);
    z-index: 2;
  }

  .prev:after,
  .next:after {
    @apply absolute w-full h-full top-0 left-0 rounded-full transition-all duration-300;
    content: "";
    box-shadow: 18px 18px 36px 0 rgba(0, 0, 0, 0.33),
      -15px -15px 36px 0 rgba(255, 255, 255, 0.3),
      inset 0 4px 42px 0 rgba(0, 0, 0, 0.06);
    background: #24272c;
    opacity: 0;
  }

  .prev.swiper-button-disabled,
  .next.swiper-button-disabled {
    @apply opacity-40;
  }

  .prev:hover:after,
  .next:hover:after {
    opacity: 0.8;
  }

  .prev {
    left: -10px;
  }

  .next {
    right: -10px;
  }

  .sw__arrow {
    @apply w-[9px] absolute top-1/2 left-1/2;
    transform: translate(-50%, -50%);
    position: relative;
    z-index: 1;
  }

  .Elips1,
  .Elips2,
  .Elips3 {
    @apply absolute w-full h-full top-0 left-0 rounded-full;
    transform: rotate(180deg);
  }

  .Elips1 {
    background: radial-gradient(
      circle at 28.23% 11.29%,
      rgb(93, 97, 103) 0%,
      rgb(19, 21, 26) 100%
    );
    filter: blur(20px);
    box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.25);
  }

  .Elips2 {
    background: radial-gradient(
      57.63% 57.63% at 45% 26%,
      rgb(255, 255, 255) 0%,
      rgb(119, 119, 119) 100%
    );
    opacity: 0.6;
  }
  .Elips3 {
    opacity: 0.6;
    user-select: none;
  }
  

  
  

  .servis__slide {
    @apply p-0  relative min-h-[515px] flex-col justify-end ;
    display: flex !important;
  }

  
}

.swiper-wrapper {
    @apply pb-10;
    height: auto !important;
}



.swiper-pagination-bullets {
    bottom: 0px !important
}
.swiper-pagination-bullet {
    background: #ffffff !important;
    position: relative;
    width: 16px !important;
    height: 16px !important;
    opacity: 1 !important;
}

.swiper-pagination-bullet-active {
    background: #fff
}

.swiper-pagination-bullet-active:after {
    @apply absolute rounded-full left-[2px] top-[2px];
    content: "";
    width: 12px;
    height: 12px;
    background: linear-gradient(
      180deg,
      rgba(42, 45, 50, 0.85) 0%,
      rgba(19, 19, 19, 0.85) 99.17%
    );
    backdrop-filter: blur(20px);
    

}


/* ------------------- */
/* Energy-healing */
/* ------------------- */
.mySwiper2 {
    /* height:130vh; */
    @apply h-[160vh]  sm:h-[155vh];
    
}







.vertical-prev,
.vertical-next {
    @apply fixed z-[1] right-[16px]  bottom-[80px] px-5 py-1  cursor-pointer
}

.vertical-prev {
    bottom: calc(60px + 25px);
}

.vertical-next {
    bottom: calc(60px - 25px);
}

.swiper-button-disabled {
    @apply opacity-40
}

.linear__bg-energy:before {
    @apply absolute w-[60%] h-[100%] right-0 -bottom-[70%]  bg-active-0 rounded-full blur-[110px] sm:blur-[230px];
    content:"";
}

.btn__cons {
 
  box-shadow: inset 26px 26px 81px 0 rgba(0, 0, 0, 0.55), inset -26px -26px 68px 0 rgba(59, 68, 81, 0.5), inset 2px 1px 2px 0 rgba(255, 255, 255, 0.05);
 
}

.mode {
  
  /* mix-blend-mode: lighten; */
}



/* RTP */
.mySwiper3 .swiper-wrapper {
  @apply sm:justify-center
}

.form__bg {
  background: linear-gradient(180deg, rgba(200, 162, 109, 1) 0%, rgba(255, 255, 255, 0) 110%);
}



.popup__bg {
  @apply bg-opacity-20 bg-black;
  backdrop-filter: blur(16px);
}

.thank__bg {
  /* @apply bg-opacity-20; */
  backdrop-filter: blur(40px);
  background-color: rgba(255, 255, 255, 0.2);
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.8); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* выше других элементов */
}

/* Добавьте стили для вашего прелоадера */
.preloader {
  /* Добавляем анимацию для постепенного исчезновения */
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.preloader.hidden {
  opacity: 0;
}


/* custom scroll */

html::-webkit-scrollbar, 
body::-webkit-scrollbar {
  width: 10px; /* Ширина полосы прокрутки */
}
html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background: #f1f1f1 ; /* Цвет фона трека */
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: #C8A26D; 
  border-radius: 5px; 
}

html::-webkit-scrollbar-thumb:hover,
body::-webkit-scrollbar-thumb:hover {
  background: #EDA94B
}




/* Lang */
.language-dropdown {
  position: relative;
  display: inline-block;
}

.language-button {
  background-color: #f1f1f1;
  color: #333;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px
}

.language-list {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: none;
}

.language-list li {
  padding: 8px 12px;
}

.language-list li button {
  text-decoration: none;
  color: #333;
}

.language-list li:hover {
  background-color: #f9f9f9;
}

.language-list li:hover button {
  color: #555;
}

.language-dropdown:hover .language-list {
  display: block;
}



.animate__animated {
 
}






